import React, { useContext, useEffect, useState } from "react";
import { ItemsContext } from "../ItemsContext";
import { supabase } from "../supabaseClient";
import TodoItem from "./TodoItem";
import UpdateItem from "./UpdateItem";

import downloadPDF from './downloadPDF';

export default function ActiveList() {
  const { getActiveItems, activeItems, loading } = useContext(ItemsContext);
  const [openModal, setOpenModal] = React.useState(false);
  const [updateData, setUpdateData] = React.useState({
    name : "",
    amount : "",
    type : "",
    draftNo : "",
    draftDated : "",
    draftDrawnOn : "",
    receiptNo : "",
    date:"",
    id: null,
  });
  

  useEffect(() => {
    supabase.auth.user() !== null && getActiveItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="row no-gutters bg-light pt-2 pb-2">
          <div className="col-sm">
            NAME
          </div>
          <div className="col-sm">
            AMOUNT
          </div>
          <div className="col-sm">
            DATE
          </div>
          <div className="col-sm text-end">
            ACTIONS
          </div>
      </div>
      {loading ? (
        "Loading..."
      ) : activeItems.length < 1 ? (
        <p className="text-center m-5"> Nothing to display ☹️ </p>
      ) : (
        activeItems.map((item, index) => (
          <TodoItem
            handleEdit={(prevValue) => {
              setOpenModal(true);
              setUpdateData({
                name : prevValue.name,
                amount : prevValue.amount,
                type : prevValue.type,
                draftNo : prevValue.draftNo,
                draftDated : prevValue.draftDated,
                draftDrawnOn : prevValue.draftDrawnOn,
                receiptNo : prevValue.receiptNo,
                date : prevValue.date,
                id: prevValue.id,
              });
            }}

            handleDownload = {(data)=>{downloadPDF(data)}}
            data={item}
            key={index.toString()}
          />
        ))
      )}

      {openModal && <UpdateItem
        open={openModal}
        setOpen={setOpenModal}
        name={updateData.name}
        amount={updateData.amount}
        type={updateData.type}
        draftNo={updateData.draftNo}
        draftDated={updateData.draftDated}
        draftDrawnOn={updateData.draftDrawnOn}
        receiptNo = {updateData.receiptNo}
        date= {updateData.date}
        id={updateData.id}
      />}

    </div>
  );
}
