import React, { useContext } from "react";
import { ItemsContext } from "../ItemsContext";

export default function UpdateItem({ name, amount, type, draftNo, draftDated, draftDrawnOn, receiptNo, id, open, setOpen, date }) {
  const [newName, setNewName] = React.useState(name);
  const [newAmount, setNewAmount] = React.useState(amount);
  const [newType, setNewType] = React.useState(type);
  const [newDraftNo, setNewDraftNo] = React.useState(draftNo);
  const [newDraftDated, setNewDraftDated] = React.useState(draftDated);
  const [newDraftDrawnOn, setNewDraftDrawnOn] = React.useState(draftDrawnOn);
  const [newReceiptNo, setNewReceiptNo]= React.useState(receiptNo);
  const [newDate, setNewDate]= React.useState(date);

  const [loading, setLoading] = React.useState(false);
  const { updateItem } = useContext(ItemsContext);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateItem({ 
        name: newName ,
        amount : newAmount ,
        type : newType ,
        draftNo : newDraftNo ,
        draftDated : newDraftDated ,
        draftDrawnOn : newDraftDrawnOn ,
        receiptNo : newReceiptNo ,
        date : newDate,
        id 
      });
    } catch (error) {
    } finally {
      setLoading(false);
      setNewName("");
      setNewAmount(0.0)
      setNewType("");
      setNewDraftNo("");
      setNewDraftDated("");
      setNewDraftDrawnOn("");
      setNewReceiptNo("");
      setNewDate("");
      setOpen(false);
    }
  };

  return (
    <>
      {open && (
        <div className="update-modal">
          <div className="col-12 col-md-6 col-xl-4">
            <div className="card">
              <div className="card-header d-flex justify-content-end">
                <button
                  onClick={() => {
                    setNewName("");
                    setOpen(false);
                  }}
                  className="icon-btn text-danger"
                >
                  X
                </button>
              </div>
              <div className="card-body">
                <form onSubmit={handleUpdate}>
                  <div className="col w-100">
                    <input
                      type="text"
                      className="form-control form-control-lg mt-2"
                      name="name"
                      required
                      value={newName }
                      onChange={(e) => setNewName(e.target.value)}
                      placeholder="Enter full name"
                    />

                    <input
                      type="number"
                      className="form-control form-control-lg mt-2"
                      name="amount"
                      required
                      value={newAmount }
                      onChange={(e) => setNewAmount(e.target.value)}
                      placeholder="Enter amount"
                    />

                    <input
                      type="date"
                      className="form-control form-control-lg mt-2"
                      name="date"
                      required
                      value={newDate }
                      onChange={(e) => setNewDate(e.target.value)}
                      placeholder="Enter date"
                    />

                    <select value={newType } onChange={(e) => setNewType(e.target.value)} className="form-control form-control-lg mt-2"> 
                      <option></option> 
                      <option>Fund</option>
                      <option>Donation</option>
                      <option>Online Transfer</option>
                      <option>Cash</option>
                      <option>Cheque</option>
                    </select>

                    <input
                      type="text"
                      className="form-control form-control-lg mt-2"
                      name="receiptNo"
                      value={newReceiptNo }
                      onChange={(e) => setNewReceiptNo(e.target.value)}
                      placeholder="Enter receipt no"
                    />

                    {/*<input
                      type="text"
                      className="form-control form-control-lg mt-2"
                      name="type"
                      required
                      value={newType }
                      onChange={(e) => setNewType(e.target.value)}
                      placeholder="Enter donation type"
                    />*/}

                    <input
                      type="text"
                      className="form-control form-control-lg mt-2"
                      name="draftNo"
                      value={newDraftNo }
                      onChange={(e) => setNewDraftNo(e.target.value)}
                      placeholder="Enter draft no"
                    />

                    <input
                      type="text"
                      className="form-control form-control-lg mt-2"
                      name="draftDated"
                      value={newDraftDated }
                      onChange={(e) => setNewDraftDated(e.target.value)}
                      placeholder="Enter draft date"
                    />

                    <input
                      type="text"
                      className="form-control form-control-lg mt-2"
                      name="draftDrawnOn"
                      value={newDraftDrawnOn }
                      onChange={(e) => setNewDraftDrawnOn(e.target.value)}
                      placeholder="Enter draft drawn on"
                    />
                  </div>
                  <div>
                    <button
                      disabled={loading}
                      className="btn btn-primary w-100 mt-3"
                    >
                      {loading ? "Updating.." : "Update"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
